document.querySelector('.navigation__item--toggle').addEventListener('click', () => {
    document.querySelector('.navigation__items').classList.toggle('navigation__items--active');
    document.body.classList.toggle('navigation--active');
});

document.querySelector('.navigation__background').addEventListener('click', () => {
    document.querySelector('.navigation__items').classList.remove('navigation__items--active');
    document.body.classList.remove('navigation--active');
});

document.addEventListener('keyup', event => {
    if (event.key === 'Escape') {
        document.querySelector('.navigation__items').classList.remove('navigation__items--active');
        document.body.classList.remove('navigation--active');
    }
});

import './base';
import '../styles/homepage.css';
import '../videos/homepage-background.mp4';

let activeHomepageCaption = 0;
const homepageCaptions = document.querySelectorAll('.homepage__caption');

setInterval(() => {
    ++activeHomepageCaption;

    homepageCaptions.forEach(element => {
        element.classList.remove('homepage__caption--active');
    });

    if (activeHomepageCaption === homepageCaptions.length) {
        activeHomepageCaption = 0;
    }

    homepageCaptions[activeHomepageCaption].classList.add('homepage__caption--active');
}, 5000);
